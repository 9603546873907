import "./blog-card-styled.scss";
import React from 'react'

const BlogCard = ({title, text, img, date}) => {
  return (
    <div className='blog-card'>
        <div className='blog-card__image'>
            <img src={img} alt=""/>
        </div>

        <div className="blog-card__content">
            <h3 className='blog-card__title'>{title}</h3>
            <p className="blog-card__description"  style={{'whiteSpace': 'pre-wrap', 'color': 'white', 'opacity': '.6'}}>{date}</p>
            <br/>
            <p className="blog-card__description"  style={{'whiteSpace': 'pre-wrap', 'color': 'white', 'opacity': '.6'}}>{text}</p>
            <br/>
          
        </div>
    </div>
  )
}

export {BlogCard}; 