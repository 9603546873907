import { Link } from "react-router-dom";
import "./logo-styled.scss";

const Logo = () => {

    return (
        <Link to="/" className="logo">Nova Leavesley</Link>
    )

}

export {Logo};